"use client"

// Import Library
import dynamic from "next/dynamic";
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Import Custom Library
import { logAction, logEventNudges } from "@/core/helpers/analytics";
import { getIsLogin } from "@/core/store/reducer/authSlice";
import { useSelector } from "react-redux";

// Import Component
const LoginContent = dynamic(() => import("@/components/login/Content"));

export default function LoginPage() {
  const isLoggedIn = useSelector(getIsLogin);

  const router = useRouter();
  const query = useSearchParams();

  const isReset = query.get('ref') === 'reset-password';
  const isActivate = query.get('activate');

  const [showMsg, setShowMsg] = useState(null);

  /**
   * Check auth when window focus
   */
  useEffect(() => {
    window.addEventListener('focus', checkUserToken);

    return () => {
      window.removeEventListener('focus', checkUserToken);
    };
  }, []);

  const checkUserToken = async () => {
    if (isLoggedIn) {
      router?.push('/user');
    }
  }

  useEffect(() => {
    if (isReset) {
      setShowMsg('Password berhasil diubah');
      setTimeout(() => {
        setShowMsg(null);
      }, 5000);
    }
    if (isActivate) {
      setShowMsg('Akun berhasil diaktifkan');
      setTimeout(() => {
        setShowMsg(null);
      }, 5000);
    }
  }, [isReset, isActivate]);

  const onSuccessLogin = () => {
    logAction('SIGNIN_COMPLETE');
    logEventNudges('SignIn_Complete');
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={
        process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY
      }
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined,
      }}
      useEnterprise={true}
    >
      <div
        className="min-h-screen mx-auto bg-white pb-10 pt-28 px-6 relative w-full sm:min-h-0 sm:max-w-[480px] sm:pt-10 sm:pb-10 sm:px-16 sm:rounded-lg"
      >
        {showMsg && (
          <div className="absolute left-0 right-0 top-[-22px] flex items-center justify-center">
            <div className="bg-neutral-90 text-sm text-white py-3.5 px-4 rounded-[16px]">
              {showMsg}
            </div>
          </div>
        )}
        <LoginContent
          onSuccessLogin={onSuccessLogin}
        />
      </div>
    </GoogleReCaptchaProvider>
  );
}
